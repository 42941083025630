/**
 * 定義畫面的檢視模式變數
 */
export function useViewMode() {
  const { locale } = useI18n()
  const member = useMember()
  return {
    /**
     * 目前頁面是否為英文版
     */
    english: computed(() => locale.value === 'en'),
    /**
     * 目前會員是否為 Agent
     */
    agent: computed(() => member.value && member.value.group === MemberGroup.Agent),
    /**
     * 目前會員是否為 Agent 或是 Admin
     */
    agentOrAdmin: computed(() => member.value && [MemberGroup.Agent, MemberGroup.Admin].includes(member.value.group)),
    /**
     * 目前會員是否為 holder
     */
    holder: computed(() => member.value && member.value.group === 'holder'),
    /**
     * 目前會員是否為 nominee
     */
     nominee: computed(() => member.value && member.value.nominee),
     /**
      * 目前會員是否為 admin
      */
      admin: computed(() => member.value && member.value.group === 'admin'),
  }
}

/**
 * <html lang="">
 */
export function useHtmlLang() {
  const { locale } = useI18n()
  const lang = ref('')
  watchEffect(() => {
    if (locale.value === 'zh') {
      lang.value = 'zh-TW'
    }
    if (locale.value === 'en') {
      lang.value = 'en-US'
    }
  })
  return lang
}